.input-container {
  position: relative;
  width: 100%;

  .postfix-container {
    position: absolute;
    display: flex;
    right: 1rem;
    top: 0;
    transform: translateY(100%);

    .icon-container {
      height: 16px;
    }
  }
}
