/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;

  /* Tooltip content */
  .tooltip-content {
    visibility: hidden;
    background-color: var(--color-font);
    color: var(--color-primary);
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
  }

  /* Tooltip arrow */
  .tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--color-font) transparent transparent transparent;
  }
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
