.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  z-index: 4;
  box-shadow: 5px 0 4px rgba(0, 0, 0, 0.08);
  background-color: var(--color-primary);

  display: flex;
  flex-direction: column;
  align-items: center;

  .logo-container {
    height: 153px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    width: 100%;

    .link {
      display: flex;
      justify-content: center;
      color: var(--color-font);
      text-decoration: none;
      margin-bottom: 15px;
      padding: 12px 16px;
      font-family: var(--font-primary);
      font-weight: 600;

      &:hover{
        background-color: var(--color-highlight);
      }

      &.active {
        background-color: var(--color-highlight);
        color: var(--color-primary);
      }
    }
  }

  .rights {
    position: fixed;
    bottom: 25px;
    font-family: var(--font-primary);
    font-weight: 600;
  }
}
