.loading-icon{
    height: 1em;
    animation: spin infinite 1s linear;
    margin-right: 0.5em;
}

@keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }