.page-selector {
  border: none;
  font-family: var(--font-primary);
  color: var(--color-font);
  font-weight: 400;
  line-height: 22px;
  font-size: 16px;
  
  &:focus {
    outline: none;
  }
}
