.child-page {
  width: calc(100% - 60px);

  .title {
    display: flex;
    flex-direction: row;

    .parent-name {
      margin-left: 10px;
    }
  }
  
  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }
  }
}

.child-profile-picture{
  height: 55px;
}

.achievement-table-container{
  width: 100%;
}

.child-modal {
    .form {
        width: 444px;
        > *:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.child-modal-input {
    gap: 7px;
    border: 1px solid #000;
    background: #fff;
    font-family: var(--font-primary);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 12px;
    width: calc(100% - 26px);

    &.error {
        border-color: red;
    }
}

.from-to {
    align-items: center;
    display: flex;
    gap: 8px;
}