.modal-container {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: white;
    padding: 20px;
    scroll-behavior: smooth;
    width: 700px;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    height: fit-content;
    max-width: 50vw;
    border-radius: 16px;
    max-height: 500px;

    &::-webkit-scrollbar {
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      height: calc(100% - 105px);
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #aaaaaa;
    }

    &::-webkit-scrollbar-track-piece:end {
      background-color: transparent;
      margin-bottom: 16px;
    }

    &::-webkit-scrollbar-track-piece:start {
      background-color: transparent;
      margin-top: 16px;
    }

    .modal-header {
      margin-bottom: 32px;
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      line-height: 28px;
      font-family: var(--font-primary);
      font-weight: bold;
      -webkit-font-smoothing: auto;
      -moz-osx-font-smoothing: auto;

      img {
        height: 18px;
      }
    }

    .modal-content {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      text-align: left;
      align-items: center;
      margin-bottom: 32px;
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;

      > *:not(:last-child){
        margin-right: 10px;
      }
    }
  }
}

.info-modal-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  .info-modal {
    position: relative;
    margin-top: 15px;
    min-width: 100px;
    width: fit-content;
    max-width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);

    &.info {
      background-color: var(--color-yellow);
    }

    &.error {
      background-color: var(--color-red);
    }

    &.success {
      background-color: var(--color-green-main);
    }

    .info-modal-header {
      height: 15px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .info-modal-body {
      font-family: var(--font-primary);
      font-weight: 700;
      color: var(--color-font);
      display: flex;
      flex-direction: row;
      align-items: center;

      .info-icon {
        img {
          height: 24px;
          margin-right: 5px;
        }
      }
    }
  }
}
