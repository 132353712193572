.poetry-subpage-breadrcumb {
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  .poetry-subpage-breadcrumb-title {
    font-size: 24px;
    font-family: var(--font-primary);
    font-weight: 600;
    line-height: 24px;
  }

}
