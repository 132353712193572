.error-title {
  display: flex;
  width: 100%;
  font-family: var(--font-primary);
  font-weight: 700;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 100%;
  font-size: 18px;
}

.forgot-password-confirm-title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  font-family: var(--font-primary);
  font-weight: 700;
  font-size: 18px;
}
