.poetry-chat-page {
  width: calc(100% - 60px);

  .top-bar-container {
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;

    .type-select {
      display: flex;
      gap: 20px;

      span {
        cursor: pointer;

        &.active {
          font-weight: 700;
        }
      }
    }
  }

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button {
      margin-left: 24px;
    }
  }
}

.poetry-chat-modal {
  .form {
    width: 444px;
    > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.poetry-chat-modal-input,
#poetry-chat-type {
  gap: 7px;
  border: 1px solid #000;
  background: #fff;
  font-family: var(--font-primary);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 12px;
  margin-bottom: 15px;
  width: calc(100% - 26px);

  &.error {
    border-color: red;
  }
}

#poetry-chat-type {
  width: 100%;
}
