.filter-icon {
  position: relative;

  .drpodown-menu {
    width: 120px;
    padding: 8px;
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid transparent;
    background-color: var(--color-primary);
    border-radius: 5px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

    .button-contanier {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }
  }
}
