.paginator-icon {
  cursor: pointer;

  &.next {
    transform: rotate(180deg);
  }

  &.active {
    svg path {
      fill: green; /* Change the fill color for the active state */
    }
  }

  &.inactive {
    cursor: not-allowed;
    svg path {
      fill: red; /* Change the fill color for the inactive state */
    }
  }
}
