.poet-page {
  width: calc(100% - 60px);

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button {
      margin-left: 24px;
    }
  }

  .poet-profile-picture {
    height: 55px;
  }
}

.picture-upload {
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  .picture-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &:first-child {
      margin-bottom: 15px;
    }

    .picture-container {
      display: flex;
      flex-direction: column;
    }

    .content-label{
      font-size: 14px;
    }
  }
}
