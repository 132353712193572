.poetry-quiz-page {
  width: calc(100% - 60px);

  .top-bar-container {
      align-items: center;
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;

      .type-select {
          display: flex;
          gap: 20px;

          a {
            cursor: pointer;

              &.active {
                  font-weight: 700;
              }
          }
      }
  }

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button{
      margin-left: 24px;
    }
  }
}

.poetry-quiz-modal {
  .form {
    width: 444px;
  }
}

.poetry-quiz-modal-input {
  gap: 7px;
  border: 1px solid #000;
  background: #fff;
  font-family: var(--font-primary);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 12px;
  margin-bottom: 15px;
  width: calc(100% - 26px);

  &.error{
    border-color: red;
  }
}

.poetry-quiz-answers-modal {
    width: 100%;

    .creation-button {
        margin-left: auto;
        margin-bottom: 10px;
        padding: 12px;
        width: 44px;
    }

    .answers-container {
        border: 1px solid var( --color-placeholder );
        border-radius: 4px;

        .answer {
            align-items: center;
            display: flex;
            padding: 15px;

            &__text {
                width: 70%;
            }

            &__solution, &__edit, &__delete {
                display: flex;
                justify-content: center;
                width: 10%;
            }
        }
    }

    .activity-circle {
        cursor: pointer;
    }
}

.poetry-quiz-infos-modal {
    width: 100%;

    .creation-button {
        margin-left: auto;
        margin-bottom: 10px;
        padding: 12px;
        width: 44px;
    }

    .infos-container {
        border: 1px solid var( --color-placeholder );
        border-radius: 4px;

        .info {
            align-items: center;
            display: flex;
            padding: 15px;

            &__text {
                width: 80%;
            }

            &__edit, &__delete {
                display: flex;
                justify-content: center;
                width: 10%;
            }
        }
    }
}

.input-modal {

    .form {
        width: 444px;
    }

    .input-modal-text {
        gap: 7px;
        border: 1px solid #000;
        background: #fff;
        font-family: var(--font-primary);
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding: 12px;
        margin-bottom: 15px;
        width: calc(100% - 26px);

        &.error{
            border-color: red;
        }
    }

}