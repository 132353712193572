.input-container {

    input[type="date"] {

        gap: 7px;
        border: 1px solid #000;
        background: #fff;
        font-family: var(--font-primary);
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding: 12px;
        width: calc(100% - 24px);

    }

}
