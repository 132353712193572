.table-container {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  position: relative;
  height: 610px;
  overflow: hidden;
  background-color: var(--color-primary);
  max-height: calc(100vh - 230px);
  &.non-height {
    height: fit-content;
  }

  .table-section {
    flex: 1;
    overflow: auto;

    .data-table {
      position: relative;
      width: 100%;
      border-collapse: collapse;
      border: none;

      .table-header {
        text-align: left;
        background-color: #dfdfdf;
        height: 36px;

        .header-string {
          color: var(--color-font);
          font-family: var(--font-primary);
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          padding: 12px;

          .inline-header {
            display: flex;
            flex-direction: row;
            align-items: center;

            .header-suffix {
              display: flex;
              margin-top: 5px;
            }
          }
        }
      }

      .data-string {
        padding: 14px;
        height: 20px;
      }
    }
  }

  .paginator-container {
    flex-shrink: 0;
    float: right;
    padding: 5px;
  }
}

.loading-table {
  width: 100%;
  padding-top: 40px;
  text-align: center;
}
