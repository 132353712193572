.file-container {
  margin-top: 5px;
  width: 200px;
  background-color: var(--color-light-gray);
  border: 1px solid transparent;
  cursor: pointer;
  height: 150px;
  color: transparent;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);

  .file-input {
    width: 200px;
    height: 150px;
    cursor: pointer;

    &::file-selector-button {
      display: none;
    }
  }

  .audio {
    width: 100%;
  }
}
