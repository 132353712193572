.login-form {
  width: 345px;
  .login-input {
    gap: 7px;
    border: 1px solid #000;
    background: #fff;
    font-family: var(--font-primary);
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 12px;
    margin-bottom: 15px;
    width: calc(100% - 24px);

    &.error{
      border-color: red;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
  }

  .forgot-password {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    .link {
      color: #000;
    }
  }
}
