.profile-page{
    display: flex;
    flex-direction: column;

    .profile-form{
        display: flex;
        justify-content: center;
        align-items: center;

        .profile-input{
            gap: 7px;
            border: 1px solid #000;
            background: #fff;
            font-family: var(--font-primary);
            border-radius: 5px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            padding: 12px;
            margin-bottom: 15px;
            width: calc(100% - 24px);
            min-width: 345px;
        
            &.error{
              border-color: red;
            }
        }

        .profile-button{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}