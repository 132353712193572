.button {
  padding: 12px 24px;
  cursor: pointer;
  background-color: var(--color-primary);
  color: var(--color-font);
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  font-size: 16px;

  &:hover {
    background-color: var(--color-highlight);
  }

  &.disabled {
    cursor: not-allowed;
    background-color: var(--color-light-grey);
  }

  &.creation-button {
    padding: 24px;
    border-radius: 5px;
  }

  &.poetry-button {
    padding: 6px;
    font-size: 14px;
  }

  &.permanent-active {
    color: var(--color-primary);
    background-color: var(--color-highlight);
  }
}
