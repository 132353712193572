.admin-page {
  width: calc(100% - 60px);

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button {
      margin-left: 24px;
    }
  }
}
