.header-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 60px;
  display: grid;
  align-items: center;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.08);
  background-color: var(--color-primary);

  .header-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .text-container {
      display: flex;
      align-items: center;
      margin-right: 35px;
      height: 20px;
      @media (max-width: 720px) {
        font-size: 12px;
      }

      img {
        margin-left: 10px;
      }

      .profile-email {
        font-weight: bold;
        color: var(--font-primary);
        text-decoration: none;
      }
    }
  }
}
