.page {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 360px;
  padding-top: 100px;
  width: calc(100vw - 360px);
  min-height: calc(100vh - 100px);
  background-color: #d9d9d9;
  overflow: auto;

  .title {
    font-size: 24px;
    font-family: var(--font-primary);
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
  }
}
