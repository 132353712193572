.tier-page {
  width: calc(100% - 60px);

  .tier-title-timereset-display {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .date-selector {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        width: 100%;
        margin-right: 10px;
      }

      .tier-reset-time-selector {
        padding: 5px;
      }
    }
  }

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button {
      margin-left: 24px;
    }
  }
  .tier-badge-url {
    height: 55px;
  }
}
.tier-form {
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 15px;
  }
}
