.poetry-page {
  width: calc(100% - 60px);

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button {
      margin-left: 24px;
    }
  }
}

.poetry-modal {
  .form {
    width: 444px;
    > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }
}

.poetry-modal-input,
#poetry-class,
#poetry-recommended,
#poetry-poet {
  gap: 7px;
  border: 1px solid #000;
  background: #fff;
  font-family: var(--font-primary);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  padding: 12px;
  width: calc(100% - 26px);

  &.error {
    border-color: red;
  }
}

#poetry-class,
#poetry-recommended,
#poetry-poet {
  width: 100%;
}

#poetry-poet {
  margin-bottom: 20px;
}

.poetry-category-select {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .label {
    font-size: 18px;
    margin-bottom: 10px;
    width: 100%;
  }

  .categories {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    width: calc(100% - 131px);

    .category {
      background-color: var(--color-highlight);
      border-radius: 4px;
      display: flex;
      font-size: 12px;
      padding: 6px 32px 6px 12px;
      position: relative;

      .close {
        cursor: pointer;
        position: absolute;
        right: 12px;
        top: 50%;
        translate: 0 -7px;
      }
    }
  }
}

.categories-list {
  display: flex;
  flex-direction: column;
  max-height: 216px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 30px;
  gap: 15px;
  width: 404px;

  .category {
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    padding: 12px;
    width: 100%;

    &.selected {
      border-color: #000000;
    }
  }
}
