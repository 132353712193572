.disappearing-page {
  width: calc(100% - 60px);

  .line-of-poetry-subpages-breadcrumb {
    display: flex;
    align-items: center;
    flex-direction: row;

    > *:not(:last-child) {
      margin-right: 10px;
    }

    .poetry-subpage-breadcrumb-title {
      font-size: 24px;
      font-family: var(--font-primary);
      font-weight: 600;
      line-height: 24px;
    }

    .subtitle {
      font-size: 24px;
      font-family: var(--font-primary);
      font-weight: 400;
      line-height: 24px;
    }

    .line-text {
      font-size: 24px;
      font-family: var(--font-primary);
      color: var(--color-placeholder);
      font-weight: 400;
      line-height: 24px;
    }
  }

  .search-bar-container {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;

    .searchbar {
      width: calc(100% - 24px);
      background-color: var(--color-primary);
      height: 24px;
      padding: 12px;
      border-radius: 6px;
      border: 1px solid #545f71;
    }

    button {
      margin-left: 24px;
    }
  }
}
.disappearing-form {
  > *:not(:last-child) {
    margin-bottom: 15px;
  }
}
